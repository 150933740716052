@import unit


$color-blue-lightest: #5CACE6
$color-blue-lighter: #3A89C2
$color-blue-light: #20699E
$color-blue: #0C4B78
$color-blue-dark: #003154 /* color-base */
$color-blue-darker: #001C30
$color-blue-darkest: #00060A
$color-red-lightest: #E66A5C
$color-red-lighter: #C2483A
$color-red-light: #9E2C20
$color-red: #78170C
$color-red-dark: #540800 /* color-base */
$color-red-darker: #300500
$color-red-darkest: #0A0100
$color-yellow-lightest: #E6C8A1
$color-yellow-lighter: #C2A274
$color-yellow-light: #9E7E4F
$color-yellow: #785B30
$color-yellow-dark: #543200 /* color-base */
$color-yellow-darker: #301D00
$color-yellow-darkest: #0A0600

$color-gray: rgb(203 213 225)

$gradient-blue: linear-gradient(to right, black, $color-blue-darker, black)
$gradient-red:  linear-gradient(to right, black, $color-red-darker, black)


@mixin border-bottom
  border-bottom: $u-px solid $color-yellow-lightest

@mixin border-top
  border-top: $u-px solid $color-yellow-lightest
