@import color


@mixin duration-hover
  transition-duration: 150ms
  &:hover
    transition-duration: 300ms

@mixin duration-focus
  transition-duration: 150ms
  &:focus
    transition-duration: 300ms

@mixin hover
  color: $color-yellow-lightest
  @include duration-hover
  &:hover
    background-color: $color-yellow-lightest
    color: white
