@import template


&#portfolio
  > div:nth-child(2)
    position: fixed
    display: flex
    flex-direction: column
    top: 0
    left: 0
    background-color: black
    width: 100%
    height: 100vh
    z-index: 400
    > nav
      flex: initial
      width: 100%
      //height: $u-40
      background-image: $gradient-blue
      display: flex
      flex-direction: row-reverse
      @include border-bottom
      > button
        //height: 100%
        background-color: transparent
        @include text-2xl
        @include hover
        padding: $u-4
    > iframe
      flex: auto
      width: 100%

  > div:nth-child(3)
    display: flex
    flex-direction: column
    grid-gap: $u-14
    margin: 0 $u-4
    > article
      display: flex
      align-items: center
      flex-direction: column
      //@media screen and (min-width: $screen-2xl)
      //  flex-direction: row
      grid-gap: $u-14
      width: 100%
      max-width: $screen-xl
      padding-bottom: $u-14

      @include border-bottom
      > div:last-child
        display: flex
        position: relative
        max-width: 450px
        width: 100%
        aspect-ratio: 4 / 3
        > img
          width: 100%
        > div
          position: absolute
          top: 0
          left: 0
          height: 100%
          width: 100%
          > button
            background-color: transparent
            width: 100%
            height: 100%
            color: $color-yellow-lightest
            @include hover
            &:hover
              background-color: rgba(red($color-yellow-lightest), green($color-yellow-lightest), blue($color-yellow-lightest), 40%)
            @include text-3xl
            @media screen and (min-width: $screen-sm)
              @include text-5xl
            @media screen and (min-width: $screen-xl)
              @include text-7xl
      > div:first-child
        width: 100%
        max-width: $screen-md
        > h3
          color: $color-yellow-lightest
          text-align: center
          font-weight: bold
          @include text-2xl
        > p
          text-align: justify
          max-width: $screen-md
