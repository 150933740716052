@import template


> header,
> section
  scroll-margin-top: $u-16
  width: 100%
  background-image: $gradient-red
  color: white
  display: flex
  flex-direction: column
  align-items: center
  > h1,
  > h2,
  > h3
    color: $color-yellow-lightest
  > h2
    width: 100%
    font-weight: bold
    text-align: center
    padding: $u-7 0
    margin: 0 0 $u-24
    background-image: $gradient-blue
    @include text-3xl
    @include border-top
    @include border-bottom
  > p
    text-align: justify
    max-width: $screen-md
    margin-left: $u-4
    margin-right: $u-4
    @include text-lg
  > p,
  figcaption
    > a
      color: $color-yellow-lightest
      text-decoration: underline
      @include duration-hover
      &:hover
        color: $color-yellow-light

> section
  padding-bottom: $u-24
  > a
    //background-color: $color-yellow-lightest
    border: 1px solid $color-yellow-lightest
    //color: white
    @include hover
    @include text-xl
    margin-top: $u-7
    margin-bottom: $u-7
    height: 100%
    padding: $u-4
> header
  @import main__home__title

> section
  @import main__home__about
  @import main__home__portfolio
  @import main__home__experience
  @import main__home__lesson
  @import main__home__repair
  @import main__home__gallery
  @import main__home__contact
