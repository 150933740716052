@import template


&#repair
  > div
    display: grid
    grid-template-columns: repeat(1, minmax(0, 1fr))
    @media screen and (min-width: $screen-lg)
      grid-template-columns: repeat(2, minmax(0, 1fr))
    @media screen and (min-width: $screen-xl)
      grid-template-columns: repeat(3, minmax(0, 1fr))
    > figure
      margin: $u-7
      max-width: 450px
      display: flex
      flex-direction: column
      grid-gap: 0
      > img
        width: 100%
        border-top-left-radius: $u-3
        border-top-right-radius: $u-3
      > figcaption
        background-color: black
        border-bottom-left-radius: $u-3
        border-bottom-right-radius: $u-3
        padding: $u-5 $u-2
        text-align: center
        display: flex
        flex-direction: column
        justify-content: center
        width: 100%
        @include text-md