@import template


.hidden
  display: none !important

*
  box-sizing: border-box

html
  font-size: 100%
  > body
    min-height: 100vh
    margin: 0

html,
button,
label,
input,
textarea
  font-family: 'Montserrat', sans-serif

input::placeholder,
textarea::placeholder
  color: #9ca3af
  opacity: 1

h1,
h2,
h3,
h4,
h5,
h6
  font-family: 'Montserrat', sans-serif

a
  color: inherit
  text-decoration: none

ul,
ol
  margin: 0
  padding: 0
  list-style: none

input,
textarea
  font-size: 100%
  line-height: inherit

button
  border: none
  &:hover
    cursor: pointer


#root
  display: flex
  flex-direction: column
  min-height: 100vh
  @import header
  @import main
  @import footer
