@import template


> div#header__alt,
> header#header
  flex: initial
  width: 100%
  a,
  button
    background-color: transparent
    @include hover
    @include text-2xl
    height: 100%
    padding: $u-4
    display: flex
    flex-direction: row
    justify-content: center
  > nav
    background-image: $gradient-blue
  > nav:nth-child(1)
    //display: grid
    //grid-template-columns: 1fr auto 1fr
    display: flex
    flex-direction: column
    width: 100%
    @include border-bottom
    > nav
      display: flex
      flex-direction: row
    > nav:nth-child(1)
      //justify-content: flex-start
      display: none
      justify-content: center
      @include border-bottom
    > nav:nth-child(2)
      display: none
      justify-content: center
    > nav:nth-child(3)
      justify-content: flex-end
    @media screen and (min-width: $screen-lg)
      > nav:nth-child(1),
      > nav:nth-child(2)
        display: flex
      > nav:nth-child(3)
        display: none
        justify-content: flex-end
  > nav:nth-child(2),
  > nav:nth-child(3)
    display: flex
    flex-direction: column
    align-items: center
    > button,
    > a
      width: 100%
      @include border-bottom
  > nav:nth-child(3)
    @include border-bottom
    > nav
      width: 100%
      max-width: $screen-xs
      display: flex
      flex-direction: row
      > a
        width: 100%
> header#header
  position: fixed
  z-index: 100