$u-0: 0px
$u-px: 1px
// $u-0.5: 0.125rem
$u-1: 0.25rem
$u-2: 0.5rem
$u-3: 0.75rem
// $u-1.5: 0.375rem /* 6px */
$u-2:   0.5rem /* 8px */
// $u-2.5: 0.625rem /* 10px */
$u-3:  0.75rem /* 12px */
// $u-3.5 0.875rem; /* 14px */
$u-4:     1rem /* 16px */
$u-5:  1.25rem /* 20px */
$u-6:   1.5rem /* 24px */
$u-7:  1.75rem /* 28px */
$u-8:     2rem /* 32px */
$u-9:  2.25rem /* 36px */
$u-10:  2.5rem /* 40px */
$u-11: 2.75rem /* 44px */
$u-12:    3rem /* 48px */
$u-14:  3.5rem /* 56px */
$u-16:    4rem /* 64px */
$u-20:    5rem /* 80px */
$u-24:    6rem /* 96px */
$u-28:    7rem /* 112px */
$u-32:    8rem /* 128px */
$u-36:    9rem /* 144px */
$u-40:    10rem /* 160px */
$u-44:    11rem /* 176px */
$u-48:    12rem /* 192px */
$u-52:    13rem /* 208px */
$u-56:    14rem /* 224px */
$u-60:    15rem /* 240px */
$u-64:    16rem /* 256px */
$u-72:    18rem /* 288px */
$u-80:    20rem /* 320px */
$u-96:    24rem /* 384px */

$u-3xl:   48rem /* 768px */
