@import template


&#title
  justify-items: center
  align-content: center
  color: $color-yellow-lightest
  > div:nth-child(1)
    width: 100%
    background-image: $gradient-red
    @include border-bottom
    > h1
      text-align: center
      @include text-3xl
      @media screen and (min-width: $screen-lg)
        @include text-5xl
    > p
      text-align: center
      @include text-lg
      @media screen and (min-width: $screen-lg)
        @include text-3xl
  > div:nth-child(2)
    //background-image: url('../file/image/home/8ffce5df-050d-400e-878f-025bc1172072.jpg')
    //background-image: url('../file/image/home/77ef7178-8f8d-490a-834b-2f197522ce97.jpg')
    background-image: url('../file/image/home/307fcd91-11c9-44ff-ba96-43ec8e02823c.jpg')
    background-repeat: no-repeat
    aspect-ratio: 1886 / 1116
    background-size: cover
    background-position: center top
    width: 100%
    min-height: $u-96
    max-height: 90vh
