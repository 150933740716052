@import template


&#contact
  > form
    margin: 0 $u-2
    display: grid
    grid-gap: $u-2 $u-2
    grid-template-columns: repeat(1, minmax(0, 1fr))
    //> ul
    //  grid-column: 1 / -1
    div:first-child
      grid-column: 1 / -1
      display: flex
      flex-direction: column
    @media screen and (min-width: $screen-lg)
      grid-template-columns: repeat(2, minmax(0, 1fr))
      > input#name
        grid-column: 1
        grid-row: 3
    > label#email,
    > textarea,
    > button
      grid-column: 1 / -1
    > label
      color: $color-yellow-lightest
      @include text-2xl
    > input,
    > textarea
      color: $color-yellow-lighter
      padding: $u-2 $u-4
      @include text-xl
    > input:focus,
    > textarea:focus
      outline: none
    > textarea
      resize: none
    > button
      background-color: transparent
      border: $u-px solid $color-yellow-lightest
      @include text-2xl
      @include hover
      padding: $u-2 $u-4