@import template


> footer#footer
  flex: initial
  width: 100%
  text-align: center
  background-image: $gradient-blue
  color: $color-yellow-lightest
  @include border-top
  @media screen and (min-width: $screen-sm)
    @include text-2xl
